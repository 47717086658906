<header
  *ngIf="SessionValue"
  class="topmenu navbar-expand-xs navbar-expand-lg container-fluid"
>
  <button
    type="button"
    class="menu-toggle"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
  >
    <span class="sr-only">Toggle navigation</span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
  </button>
  <!-- brand -->
  <a href="/dashboard">
    <div class="logo-expanded">
      <!-- <i class="lnr icon-biobug"></i> -->
      <span class="nav-label logo-bio">QINECSA</span>
      <span class="nav-label logo-product">SIMPLE SAFETY</span>
    </div>
  </a>
  <ul
    class="navbar-nav top-right-menu justify-content-end float-right ml-auto"
    style="flex-direction: row !important"
  >
    <li class="nav-item dropdown">
      <a
        class="nav-link"
        href="#User/add#"
        id="dropdownMenuLink"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style="
          padding-right: 0.75rem !important;
          padding-left: 0.75rem !important;
        "
      >
        <i class="lnr icon-user"></i>
      </a>
      <ul
        class="dropdown-menu"
        aria-labelledby="dropdownMenuLink"
        x-placement="bottom-start"
        style="
          position: absolute;
          will-change: transform;
          top: 0px;
          left: 0px;
          transform: translate3d(0px, 55px, 0px);
        "
      >
        <li>
          <a class="dropdown-item">{{ currentUser }}</a>
        </li>
        <div class="dropdown-divider"></div>
        <li><a class="dropdown-item" (click)="LogOut()">Log Out</a></li>
      </ul>
    </li>
  </ul>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link role_nav">Role : {{ role }}</a>
      </li>
    </ul>
  </div>
</header>

<div id="wrapper-profile" *ngIf="SessionValue">
  <!-- Sidebar Start-->
  <aside
    class="left-panel"
    tabindex="5000"
    style="outline: none"
    id="side-bar"
    [ngStyle]="{
      'width.px': contentWidth,
      overflow: contentOverflow,
      left: leftMobile
    }"
  >
    <!-- Sidebar Toggle -->
    <button
      (click)="onToolbarMenuToggle()"
      type="button"
      class="sidebar-toggle navbar-toggle pull-left"
      [ngStyle]="{
        display: isMenuOpen == true || screenWidth < 768 ? 'block' : 'none'
      }"
    >
      <span class="sidebar-arrow"></span>
    </button>
    <span [ngStyle]="{ display: screenWidth > 768 ? 'block' : 'none' }">
      <button
        type="button"
        (click)="onToolbarMenuToggle()"
        class="pagebar-toggle navbar-toggle pull-left large-screen-toggle"
        [ngStyle]="{ display: isMenuOpen == false ? 'block' : '' }"
      >
        <span class="pagebar-arrow"></span>
      </button>
    </span>

    <!-- Profile-card -->
    <div class="profile-card">
      <div class="profile-title"></div>
    </div>
    <!-- Navbar Start -->
    <nav class="navigation">
      <div class="clearfix"></div>
      <ul class="list-unstyled components">
        <li *ngIf="isAuthenticated('dashboard', 'dashboard')">
          <a routerLink="/dashboard"
            ><i class="lnr icon-home"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Dashboard</span
            ></a
          >
        </li>

        <li *ngIf="isAuthenticated('roles', 'roles')">
          <a routerLink="/roles"
            ><i class="lnr icon-users2"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Roles</span
            ></a
          >
        </li>

        <li *ngIf="isAuthenticated('users', 'users')">
          <a routerLink="/users"
            ><i class="lnr icon-users2"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Users</span
            ></a
          >
        </li>
        <li *ngIf="isAuthenticated('workflow', 'workflow')">
          <a routerLink="/workflow"
            ><i class="lnr icon-folder"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Workflow</span
            ></a
          >
        </li>

        <li *ngIf="isAuthenticated('report', 'report')">
          <a routerLink="/reports"
            ><i class="lnr icon-chart-bars"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Reports</span
            ></a
          >
        </li>
        <li *ngIf="isAuthenticated('line-report', 'line-report')">
          <a routerLink="/reports/line-list"
            ><i class="lnr icon-chart-bars"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Linelisting Reports</span
            ></a
          >
        </li>
        <li *ngIf="isAuthenticated('case', 'worklist')" class="has-submenu">
          <a routerLink="/case/worklist"
            ><i class="lnr icon-list4"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Case Worklist</span
            ></a
          >
        </li>
        <li *ngIf="isAuthenticated('study-protocol', 'study-protocol')">
          <a routerLink="/study-protocol"
            ><i class="lnr icon-folder"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Study Protocol</span
            ></a
          >
        </li>
        <li *ngIf="isAuthenticated('product-dictionary', 'product-dictionary')">
          <a routerLink="/product-dictionary"
            ><i class="lnr icon-folder"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Product Dictionary</span
            ></a
          >
        </li>
        <li *ngIf="isAuthenticated('company-unit', 'company-unit')">
          <a routerLink="/company-unit"
            ><i class="lnr icon-control_point_duplicate"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Company Unit</span
            ></a
          >
        </li>
        <li *ngIf="isAuthenticated('meddra-dictionary', 'meddra-dictionary')">
          <a routerLink="/meddra-dictionary/load-meddra"
            ><i class="lnr icon-folder-upload22"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Load Dictionary</span
            ></a
          >
        </li>
        <li *ngIf="isAuthenticated('distribution-contacts', 'distribution-contacts')">
          <a routerLink="/distribution-contacts"
            ><i class="lnr icon-folder-upload22"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Submission contacts</span
            ></a
          >
        </li>
        <li *ngIf="isAuthenticated('duplicate-search', 'duplicate-search')">
          <a routerLink="/duplicate-search/duplicate-search"
            ><i class="lnr icon-control_point_duplicate"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Duplicate Search Configuration</span
            ></a
          >
        </li>
        <li *ngIf="isAuthenticated('audit-trail', 'audit-trail')">
          <a routerLink="/audit-trial"
            ><i class="lnr icon-book3"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Audit Trial</span
            ></a
          >
        </li>
        <li *ngIf="isAuthenticated('narratives', 'narratives')">
          <a routerLink="/narratives"
            ><i class="lnr icon-book3"></i
            ><span
              [ngStyle]="{ visibility: contentVisiablity }"
              class="nav-label"
              >Narratives</span
            ></a
          >
        </li>
      </ul>
    </nav>
  </aside>
  <div class="page-wrapper pb-0">
    <div class="page-content mb-3">
      <div
        class="pagebar container-fluid col-md-12"
        [ngStyle]="{ 'margin-left.px': contentMarginLeft }"
      >
        <!-- Sidebar Toggle -->
        <button
          (click)="onToolbarMenuToggleMobile($event)"
          type="button"
          class="pagebar-toggle navbar-toggle pull-left"
        >
          <span class="pagebar-arrow"></span>
        </button>
        <!-- <div style="width: 100%; height: auto;">
            <ol class="breadcrumb">
              <li *ngFor="let breadcrum of breadcrumb">
                <a *ngIf="breadcrum['replaced']==true" style="text-transform: capitalize;cursor:pointer;">{{breadcrum['label']}}</a>
                <a *ngIf="breadcrum['replaced']==false" [routerLink]="breadcrum['url']" style="text-transform: capitalize;">{{breadcrum['label']}}</a>
                
              </li>
              <li *ngFor="let routes of route;">
                <a  [routerLink]="routes" style="text-transform: capitalize;">
                  {{ routes}}
                </a>
              </li> 
            </ol> -->
        <!-- <app-breadcrumb></app-breadcrumb>
          </div> -->
      </div>
      <div class="card" [ngStyle]="{ 'margin-left.px': main_content }">
        <div class="card-body">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <div
      class="footer container-fluid col-md-12"
      [ngStyle]="{ 'margin-left.px': main_content }"
    >
      <p><i class="lnr icon-copyright"></i> QINECSA 2022. Version 2.2</p>
    </div>
  </div>
</div>
