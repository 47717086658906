import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from 'src/app/common/global-constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private baseUrl = GlobalConstants.ApiBaseUrl;
  private _dashboardCounts = new BehaviorSubject<any[]>([]);
  private dataStore: { dashboardCounts: any[] } = { dashboardCounts: [] };
  readonly dashboardCounts = this._dashboardCounts.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  loadDashboardCounts() {
    this.http.get(`${this.baseUrl}dashboard/counts`).subscribe((result:any) => {
      this.dataStore.dashboardCounts = result['data'];
      this._dashboardCounts.next(Object.assign({}, this.dataStore).dashboardCounts);
    }, error => console.log('Could not load field.'));
  }

  searchCase(case_no:String) {
    var data = { search_term: case_no };
    const config = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${this.baseUrl}dashboard/search`, JSON.stringify(data), {headers: config});
  }

}
