<div class="card">
  <div class="card-header" *ngIf="header">
    <div class="card-title">
      {{ header }}
    </div>
  </div>
  <div class="card-body">
    <canvas baseChart width="500" height="215"
    [datasets]="barChartData" 
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [colors]="barChartColors" 
    [plugins]="barChartPlugins" 
    [legend]="barChartLegend" 
    [chartType]="barChartType">
    </canvas>
  </div>
</div>
