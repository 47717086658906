import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/common/global-constants';
import { ToastService } from 'src/app/modules/shared/service/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
    temp: any;
    constructor(private httpClient: HttpClient, public toastService: ToastService, private router: Router,
        private spinner: NgxSpinnerService, private location: Location,) {
    }

    public get currentUserValue() {
        return JSON.parse(localStorage.getItem('user_info_json'));
    }

    public get currentUserRole() {
        return localStorage.getItem('current_role');
    }

    public get currentUserRolePermissions() {
        return localStorage.getItem('current_role_permissions');
    }

    public get application_parameters() {
        return localStorage.getItem('application_parameters');
    }

    public get currentToken() {
        return localStorage.getItem('token');
    }

    public get currentTenant() {
        return localStorage.getItem('current_tenant');
    }

    public tenantRoles(): Observable<any> {
        const roles = { "filter_by": { "role_status": 1 }, "order_by": "role_id", "sort_order": "asc", "current_page": 1, "start_index": 1, "page_size": 100 }
        return this.httpClient.post(GlobalConstants.ApiBaseUrl + "role/list", {
            data: roles
        }).pipe(
            map(data => {
                return data;
            }));

    }

    public VerifyUser(UserData) {
        this.spinner.show();
        this.httpClient.post(GlobalConstants.ApiBaseUrl + "auth/auth_post", {
            data: UserData
        }).subscribe(
            (response: any) => {
                this.spinner.hide();
                if (response['error']) {
                    if (response['error_description']) {
                        this.toastService.show(response['error_description'] + " or password expired", {
                            classname: 'bg-error',
                            delay: 5000,
                            autohide: true
                        });
                    } else {
                        this.toastService.show(response['error'], {
                            classname: 'bg-error',
                            delay: 5000,
                            autohide: true
                        });
                    }

                } else if (Object.keys(response).length === 0) {
                    this.toastService.show('Access Denied', {
                        classname: 'bg-error',
                        delay: 5000,
                        autohide: true
                    });
                } else if (Object.keys(response).length > 0) {
                    // console.log(response['app_params']);  return;
                    localStorage.setItem('token', response['id_token']);
                    localStorage.setItem('user_info_json', JSON.stringify(response['user_info_json']));
                    localStorage.setItem('current_role', response['current_role']);
                    localStorage.setItem('current_role_permissions', JSON.stringify(response['current_role_permissions']));
                    localStorage.setItem('application_parameters', JSON.stringify(response['app_params']));
                    var current = new Date();
                    this.temp = current.getTime();
                    localStorage.setItem('session_time', response['session_time']);
                    localStorage.setItem('active', this.temp);
                    location.reload();
                }
            },
            (error) => {
                this.spinner.hide();
                this.toastService.show(error, {
                    classname: 'bg-error',
                    delay: 5000,
                    autohide: true
                });
            }

        )
    }

    LogOut() {
        const endPoint = GlobalConstants.UiBaseUrl;
        localStorage.clear();
        window.location.href = endPoint;
    }

    LastActive() {
        if (this.currentUserValue) {
            var session_time = parseInt(localStorage.getItem('session_time'));
            var last_active = parseInt(localStorage.getItem('active'));
            var diff = this.calculateDiff(last_active);
            if (diff < session_time) {
                var current = new Date();
                this.temp = current.getTime();
                localStorage.setItem('active', this.temp);
            } else {
                this.toastService.show('SESSION EXPIRED', {
                    classname: 'bg-error',
                    delay: 2000,
                    autohide: true
                });
                this.LogOut();
            }
        }
    }

    calculateDiff(data) {
        let currentDate = new Date();
        let minutes = Math.floor((currentDate.getTime() - data) / 1000 / 60);
        return minutes;
    }

    isAuthenticated(module: string, action: string) {
        let status = false;
        const defaultRoutes = ['dashboard'];
        const currentRolePermissions = JSON.parse(this.currentUserRolePermissions) || [];
        const permission = currentRolePermissions.filter((res: any) =>
            (res.action == action) &&
            (res.module == module) &&
            (action != undefined && module != undefined));
        if (permission.length > 0 || String(this.currentUserRole) == "0" || defaultRoutes.indexOf(action) > -1) {
            status = true;
        }
        return status;
    }
}