import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartScales, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements OnChanges {

  @Input('header') header = "";
  @Input('data') data = [];

  barChartOptions: ChartOptions;
  barChartLabels: Label[];
  // barChartData: SingleDataSet;
  barChartData: ChartDataSets[];
  barChartType: ChartType;
  barChartLegend: boolean;
  barChartPlugins = [];
  barChartColors: Color[];

  constructor() {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnChanges() {
    let d = [];
    let l = [];
    this.data.map((c, i) => {
      l.push(String(c.label));
      if (c.value == null) {
        d.push('0');
      } else {
        d.push(c.value);
      }
    });
    // d = [98, 8, 3]
    this.barChartLabels = l;
    this.barChartData = [{
      data: d,
      barPercentage: 0.9,
      categoryPercentage: 0.55,
      barThickness: 70,
    }];
    this.barChartType = 'bar';
    this.barChartLegend = false;
    this.barChartColors = [
      {
        backgroundColor: ['rgba(249,139,44,0.7)', 'rgba(228,52,8,0.7)', 'rgba(34,117,8,0.7)'],
      }
    ];
    this.barChartOptions = this.createOptions(d);
  }

  private createOptions(data): any {
    return {
      responsive: true,
      tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            let count = data
                .datasets[tooltipItem.datasetIndex]
                .data[tooltipItem.index];
            return "Number of cases : " + count;
          }
        }
      },
      scales: {
        xAxes: [ {
          ticks: {
            padding: 0,
            fontColor: "#9e9e9e"
          },
          gridLines: { 
            display:false,
            drawBorder: false
          }
        } ],
        yAxes: [ {
          ticks: {
            display:true,
            min: 0,
            max: Math.max.apply(null, data) + Math.round(Math.max.apply(null, data)/10)+1,
            beginAtZero: true,
            stepSize: 1,
            fontColor: "#fff"
          },
          gridLines: { 
            display:false,
            drawBorder: false
          },
          scaleLabel: {
            display: true,
            labelString: "Number of Cases",
           },
        } ]
      },
      legend: { 
        position: 'top',
        labels: {
          fontSize: 12,
          usePointStyle: true
        }
      },
      plugins: {
        labels: {
          render: 'value',
          showZero: true,
          textMargin: 0,
          fontColor: ['rgba(249,139,44,0.7)', 'rgba(228,52,8,0.7)', 'rgba(34,117,8,0.7)'],
          fontSize:14,
          textShadow: true,
          shadowBlur: 2,
          shadowOffsetX: -2,
          shadowOffsetY: 2,
        }
      },
    }
  }

}
