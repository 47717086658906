import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ToastComponent } from './component/toast/toast.component';
import { ConfirmDialogComponent } from './component/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './service/confirm-dialog.service';

import { ResizableComponent } from './component/resizable/resizable.component';
import { ResizableDirective } from './directive/resizable/resizable.directive';


@NgModule({
  declarations: [
    ToastComponent,
    ConfirmDialogComponent,
    ResizableComponent,
    ResizableDirective
  ],
  imports: [
    CommonModule,
    NgbModule
  ],
  exports:[
    ToastComponent,
    ConfirmDialogComponent
  ],
  providers: [  
    ConfirmDialogService  
  ]
})
export class SharedModule { }
