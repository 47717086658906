import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';
import { FormArray, FormBuilder, FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { AuthorizationService } from 'src/app/modules/authorization/authorization.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as forge from 'node-forge';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  rsa = forge.pki.publicKeyFromPem(GlobalConstants.PublicKey);
  ForgetPassword_URI = GlobalConstants.ForgetPassword_URI + "?" + encodeURIComponent(GlobalConstants.UiBaseUrl);
  UpdatePassword_URI = GlobalConstants.UpdatePassword_URI + "?" + encodeURIComponent(GlobalConstants.UiBaseUrl);
  submitted = false;
  // loginForm: FormGroup
  selectedRole: number;
  count: string;

  roles: any[] = [];

  ngOnInit(): void {

  }

  constructor(private formBuilder: FormBuilder,
    private AuthorizationService: AuthorizationService) {
  }

  loginForm = this.formBuilder.group({
    customerName: [null, [Validators.required,Validators.minLength(3)]],
    UserName: [null, [Validators.required, Validators.email]],
    Password: [null, [Validators.required]],
    customerRole: [null, Validators.required],
  });

  // convenience getter for easy access to form fields
  get f() { 
    return this.loginForm.controls; 
  }

  requestRolesList() {
    if (this.loginForm.controls.customerName.valid){
      
      var customerName = window.btoa(this.rsa.encrypt(this.loginForm.get('customerName').value));
      localStorage.setItem('current_tenant_name', this.loginForm.get('customerName').value);
      localStorage.setItem('current_tenant', customerName);
      localStorage.setItem('token', customerName);
       this.AuthorizationService.tenantRoles().subscribe((data: any) => {
         
        this.roles = data.data.result_records;
        this.roles.unshift({'role_id':0,'role_name':'Admin'});
      },
        (error: HttpErrorResponse) => {
          this.roles =[]
          this.loginForm.controls.customerRole.reset();
        });
    }
  }

  showConfirm(content) {
    (document.getElementsByClassName('ng-placeholder'))[0].innerHTML = "Select Role";
    if (content>=0 && content != null){
      document.getElementsByClassName('ng-select-container')[0].className="ng-select-container";
      (document.getElementsByClassName('ng-placeholder'))[0].innerHTML = "";
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.controls.customerRole.invalid){
      // console.log(this.loginForm.get('customerRole').value)
      document.getElementsByClassName('ng-select-container')[0].className="ng-select-container ng-select-container_error";
    }else{
      if (this.submitted== true && this.loginForm.controls.customerRole.valid && this.loginForm.controls.UserName.valid &&  this.loginForm.controls.Password.valid){
        var UserData = {
          "username": this.loginForm.get('UserName').value,
          "role": this.loginForm.get('customerRole').value,
          "password": window.btoa(this.rsa.encrypt(this.loginForm.get('Password').value))
        };
        this.AuthorizationService.VerifyUser(UserData);
      }
    }

  }


}
