import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Router } from '@angular/router';
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { AppComponent } from 'src/app/app.component';
import { ToastService } from '../service/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizationService } from 'src/app/modules/authorization/authorization.service';
@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(public AuthorizationService: AuthorizationService,
        public toastService: ToastService,
        public router: Router,
        public spinner: NgxSpinnerService) {
        this.AuthorizationService.LastActive();
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const modified = req.clone({
            setHeaders: {
                "authorization": localStorage.getItem('token'),
                "tenant-code": localStorage.getItem('current_tenant')
            }
        });

        return next.handle(modified).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if (evt.body.error == 0 && evt.body.status_code == 200) {
                        // show toastr message
                        if (evt.body.message) {
                            this.spinner.hide();
                            this.toastService.toasts = [];
                            this.toastService.show(evt.body.message, {
                                classname: 'bg-success',
                                delay: 2000,
                                autohide: true
                            })
                        }
                    }
                }
            }),
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status == 0) {
                        // Server connection error
                        this.spinner.hide();
                        this.toastService.toasts = [];
                        this.toastService.show("Could not connect to the server. Something went wrong!", {
                            classname: 'bg-error',
                            delay: 5000,
                            autohide: true
                        });
                    } else if ((err.status == 401 || err.status == 404 || err.status == 500)
                        && err.error.message != "SESSION EXPIRED") {
                        // Server error
                        this.spinner.hide();
                        this.toastService.toasts = [];
                        this.toastService.show(err.error.message, {
                            classname: 'bg-error',
                            delay: 5000,
                            autohide: true
                        });


                    } else if (err.status == 401 && err.error.message == "SESSION EXPIRED") {
                        // clear the local storage and redirect to login page
                        this.spinner.hide();
                        this.toastService.toasts = [];
                        this.toastService.show(err.error.message, {
                            classname: 'bg-error',
                            delay: 2000,
                            autohide: true
                        });
                        this.AuthorizationService.LogOut();
                    } else if (err.status == 402){
                        this.spinner.hide();
                        this.toastService.toasts = [];
                        this.toastService.show(err.error.message, {
                            classname: 'bg-warning',
                            delay: 5000,
                            autohide: true
                        });
                    }
                }
                // return of(err);
                return HttpErrorResponse;
            }));

    }

}
