import { Component } from '@angular/core';
import { HostListener } from "@angular/core";
import { HttpErrorResponse} from '@angular/common/http';
import {Router, ActivatedRoute, Params,RoutesRecognized} from '@angular/router';
import{ GlobalConstants } from './common/global-constants';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { not } from '@angular/compiler/src/output/output_ast';
import { AuthorizationService } from 'src/app/modules/authorization/authorization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'SIMPLE SAFETY';
  
  SessionValue:string = "";
  // app_version = '';

  
  ngOnInit(): void {
    // console.log("app");
    
  }
  
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private location: Location,private AuthorizationService: AuthorizationService) {
                this.SessionValue = AuthorizationService.currentUserValue
  }
}
