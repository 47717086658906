<div id="contentWrapper">
    <div id="content">
        <div>
            <img class='logoImage' id='companyLogo' src='assets/logo_login.png' alt='logo_login'/>
            <h2 class="logo-product mt-2">SIMPLE SAFETY</h2>
        </div>
        <div id="authArea" class="groupMargin">
          <div id="loginArea">
            <form [formGroup]="loginForm" id="loginForm" autocomplete="off" (ngSubmit)="onSubmit()" >
                <div class="form-group">
                    <input (blur)="requestRolesList()"  [ngClass]="{ 'is-invalid': submitted && f.customerName.errors }" id="customerNameInput" formControlName="customerName" type="text" value="" tabindex="1" class="form-control"
                        spellcheck="false"  placeholder="Customer ID" autocomplete="off"/>
                </div>
                <div class="form-group">
                    <input [ngClass]="{ 'is-invalid': submitted && f.UserName.errors }" id="userNameInput" formControlName="UserName"  type="email" value="" tabindex="1" class="form-control"
                    spellcheck="false" placeholder="someone@example.com" autocomplete="off" minlength='5' maxlength="50"/>
                </div>
                <div class="form-group">
                    <input  [ngClass]="{ 'is-invalid': submitted && f.Password.errors }" id="passwordInput" formControlName="Password" type="password" tabindex="2" class="form-control"
                    placeholder="Password" autocomplete="off"/>
                </div>
                <div class="form-group">
                    <ng-select  class="ng-select.custom" (ngModelChange)="showConfirm($event)" formControlName="customerRole" [items]="roles" bindLabel="role_name" bindValue="role_id" placeholder="Select Role">
                    </ng-select>
                    
                </div>
                <div class="mb-1">
                    <a id='ForgotPasswordLink' style="display:none" class="float-left" href={{ForgetPassword_URI}}>Forgot Password</a> 
                    <a id='UpdatePasswordLink' class="float-right" href={{UpdatePassword_URI}}>Update Password</a>
                </div>
                <div id="submissionArea" class="submitMargin">
                    <button id="submitButton"  type="submit" class="submit" tabindex="4" role="button">Sign in</button>
                </div>
            </form>
  
            <!-- <div id="linkButtons">
              <a id='TermsOfUse' class='linkButton mr-1' href='https://www.bioclinica.com/terms-use' >Terms of Use</a>
              <a id='PrivacyPolicy' class='linkButton ml-1' href='https://www.bioclinica.com/privacy-policy'>Privacy Policy</a>
            </div>   -->
          </div>
        </div>
    </div>
  </div>