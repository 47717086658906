import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HealthcheckComponent } from './modules/healthcheck/healthcheck.component';
import { LoginComponent } from './modules/login/login.component';
import { AuthGuard } from './modules/shared/helpers/auth.guard';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { HomeComponent } from './modules/dashboard/components';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      'action': 'dashboard',
      'module': 'dashboard'
    }
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'healthcheck', component: HealthcheckComponent
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules').then(m => m.DashboardModule)
  },
  {
    path: 'roles',
    loadChildren: () => import('./modules').then(m => m.RolesModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./modules').then(m => m.UsersModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules').then((m) => m.ReportModule)
  },
  {
    path: 'workflow',
    loadChildren: () => import('./modules').then(m => m.WorkflowModule)
  },
  {
    path: 'workflow-activity',
    loadChildren: () => import('./modules').then(m => m.WorkflowActivityModule)
  },
  {
    path: 'activity',
    loadChildren: () => import('./modules').then(m => m.ActivityModule)
  },
  {
    path: 'study-protocol',
    loadChildren: () => import('./modules').then(m => m.StudyProtocolModule)
  },
  {
    path: 'product-dictionary',
    loadChildren: () => import('./modules').then(m => m.ProductDicitionaryModule)
  },
  {
    path: 'case',
    loadChildren: () => import('./modules').then(m => m.CaseModule)
  },
  {
    path: 'meddra-dictionary',
    loadChildren: () => import('./modules').then(m => m.MeddraDictionaryModule)
  },
  {
    path: 'duplicate-search',
    loadChildren: () => import('./modules').then(m => m.DuplicateSearchModule)
  },
  {
    path: 'company-unit',
    loadChildren: () => import('./modules').then(m => m.CompanyUnitModule)
  },
  {
    path: 'audit-trial',
    loadChildren: () => import('./modules').then(m => m.AuditTrailModule)
  },
  { 
    path: 'application-parameters',
    loadChildren: () => import('./modules').then(m => m.ApplicationParametersModule) 
  },
  {
    path: 'distribution-contacts',
    loadChildren: () => import('./modules').then(m => m.DistributionContactsModule)
  },
  {
    path: 'narratives',
    loadChildren: () => import('./modules').then(m => m.NarrativesModule)
  },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

