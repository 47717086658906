export class GlobalConstants {
    public static ApiBaseUrl: string = "https://simplesafetyapi.qinecsa.com/";
    public static UiBaseUrl: string = "https://simplesafety.qinecsa.com/";
    public static CLIENT_ID: string = "24qah03dsp9phti1mg8tnblc75";
    public static ISSUER: string = "https://prod-sigtrace.auth.us-east-1.amazoncognito.com";
    public static REDIRECT_URI: string = "https://simplesafety.qinecsa.com";
    public static KIABANA_URI: string="{{KIABANA_URI}}";
    public static ApiBaseUrlDirect: string="https://simplesafetyapi.qinecsa.com/";
    public static ForgetPassword_URI: string = "#";
    public static UpdatePassword_URI: string = "https://qinawsfs.qinecsa.com/adfs/portal/updatepassword";
    public static TermsOfUse_URI: string = "https://www.bioclinica.com/terms-use";
    public static PrivacyPolicy_URI: string = "https://www.bioclinica.com/privacy-policy";
    public static PublicKey: string = `-----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApfQ0WTHeZhWbKMFJo2CW
    FFOXYlb2/N6vhyxhNkk9t9n27ZYhOZq6jeVVES8V6B9PemuBYpXzg/jQ2Lbhpycy
    3A9w1S+hcZoC7e21ptUNMQtzpKcXYKh0xvf5D1UNy76cqnNGjTDm5Tmxk9vVu1Og
    1Z+XxHtbB9snQVFBquyLxpxIkJaaEH4WRpBc21iugnAVob9o13h52mgkEN5WeFJK
    N6ya350C1G8OPzxZys9Ebm0ghNMVxum123EKqHS0UVoyhhXTXnBV17U+PCpOS6aD
    aBpM3yIwMQWjgmDjq93ZimU8eShHCDSio9tUdrQ0QUG4bIsS4uMtoFkBYtZg7FTO
    YQIDAQAB
    -----END PUBLIC KEY-----`;
}
