import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-labels';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnChanges {

  @Input('header') header = "Pie Chart";
  @Input('data') data = [];

  pieChartOptions: ChartOptions;
  pieChartLabels: Label[];
  pieChartData: SingleDataSet;
  pieChartType: ChartType;
  pieChartLegend: boolean;
  pieChartPlugins = [];
  pieChartColors: Color[];


  constructor() {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnChanges() {
    this.pieChartOptions = this.createOptions();
    let d = [];
    let l = [];
    this.data.map((c) => {
      l.push(String(c.label));
      if (c.value == null) {
        d.push('0');
      } else {
        d.push(c.value);
      }
    });
    this.pieChartLabels = l;
    this.pieChartData = d;
    // this.pieChartLabels = ['Triage', 'Data Entry', 'Quality review', 'Medical Review'];
    // this.pieChartData = [300, 500, 100, 200];
    this.pieChartType = 'pie';
    this.pieChartLegend = true;
    this.pieChartPlugins = [pluginDataLabels];
    this.pieChartColors = [
      {
        backgroundColor: [
          'rgba(228,52,8,0.6)', 
          'rgba(34,117,8,0.6)', 
          'rgba(52,20,196,0.6)', 
          'rgba(249,139,44,0.6)',
          'rgba(32,201,151,0.6)'
        ],
      }
    ];
  }

  private createOptions(): ChartOptions {
    return {
      responsive: true,
      tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            let label = data.labels[tooltipItem.index];
            let count = data
                .datasets[tooltipItem.datasetIndex]
                .data[tooltipItem.index];
            return "Number of cases in "+ label+ " : " + count;
          }
        }
      },
      maintainAspectRatio: true,
      cutoutPercentage: 1,
      legend: { 
        position: 'right',
        labels: {
          fontSize: 12,
          usePointStyle: true
        }
      },
      plugins: {
        labels: {
          render: 'value', // 'label', 'percentage', 'value', 'image'
          fontColor: '#fff',
          fontSize:14,
          // showZero: true,
          textShadow: true,
          shadowBlur: 2,
          shadowOffsetX: -5,
          shadowOffsetY: 5,
          // shadowColor: 'rgba(0,0,0,0.3)',
          position: 'default', //'default', 'border' and 'outside'
        }
      },
    };
  }

}
