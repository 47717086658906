<div class="row col-md-6 ml-auto mr-3">
    <div class="col-md-11">
        <input type="text" [(ngModel)]="term" class="form-control" 
        placeholder="Search Case Number" maxlength="15" />
    </div>
    <div class="col-md-1">
        <button (click)="onSearch()" class="btn btn-primary rounded mr-3">Open</button>
        <button style="display: none;" id="auto_trigger" (click)="showDialog($event,searchId)"></button>
    </div>
</div>
<div class="card ml-3 mt-3">
    <div class="card-body">
        <div class="row">
            <app-pie-chart [header]="'Workflow case count'" [data]="pieChartData" class="col-md-6 mb-1"></app-pie-chart>
            <!-- <app-bar-chart [header]="'Workflow case count'" class="col-md-6"></app-bar-chart> -->
            <div class="col-md-6 mb-1">
                <!-- <div class="card">
                    <div class="card-body"> -->
                        <div class="row">
                            <div class="col" *ngFor="let bar of barChartData; let i=index">
                                <div [attr.style]="'border-bottom: 3px solid '+barLegendColors[i]+'; height: 25px;'">
                                    <b>{{ bar['label'] }}</b>
                                </div>
                                <div style="font-size: large;" *ngIf="bar['value'] != null; else barNull">
                                    {{ bar['value'] }}
                                </div>
                                <ng-template #barNull>
                                    <div style="font-size: large;">0</div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <app-bar-chart class="col-md-12" [data]="barChartData"></app-bar-chart>
                        </div>
                    <!-- </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<app-confirm-dialog></app-confirm-dialog>
